import React from 'react'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { mobileAbout, about } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          center
          // h="500px"
          // image={`url(${mobileAbout}) center / cover no-repeat`}
          // style={{ backgroundAttachment: 'fixed' }}
        >
          <CFImage src={mobileAbout} w="100%" alt="About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" maxWidth="1400px">
          <CFImage w="100%" src={about} alt="About" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
